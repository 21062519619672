<template>
  <div class="card p-1">
    <div class="custom-search">
      <div class="title head">
        <p class="h2" style="color: #558cef">รายงานแยกตามกลุ่มงาน</p>
      </div>
      <hr />
      <!-- advance search input -->
      <div class="custom-search row justify-content-center">
        <b-form-group class="col-12 col-md-4">
          <div class="align-items-center">
            <label class="mr-1">จากวันที่</label>
            <v-date-picker id="example-datepicker3" label="จากวันที่:" locale="th-TH" v-model="date1"
              @update:from-page="getHeaderInputDate">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input :value="formatDate(inputValue, 'date1')" v-on="inputEvents" />
              </template>
              <template #header-title>
                {{ headerInputDate }}
              </template>
            </v-date-picker>
          </div>
        </b-form-group>
        <b-form-group class="col-12 col-md-4">
          <div class="align-items-center">
            <label class="mr-1">ถึงวันที่</label>
            <v-date-picker id="example-datepicker3" label="ถึงวันที่:" locale="th-TH" v-model="date2"
              @update:from-page="getHeaderInputDate">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input :value="formatDate(inputValue, 'date2')" v-on="inputEvents" />
              </template>
              <template #header-title>
                {{ headerInputDate }}
              </template>
            </v-date-picker>
          </div>
        </b-form-group>
        <b-form-group class="col-12 col-md-4">
          <label>กลุ่มงาน:</label>
          <v-select v-model="workgroup" :options="workgroups" label="work_gid_work_gname" placeholder="เลือกกลุ่มงาน"
            @input="departments_table(workgroup)" />
        </b-form-group>
      </div>

      <!-- <b-col md="4">
          <b-form-group>
            <label>กลุ่มวัสดุ:</label>
            <div v-if="workgroup === null">
              <v-select
                v-model="equipmentGroup"
                :options="equipmentGroups"
                label="m_gid_m_gname"
                placeholder="เลือกกลุ่มวัสดุ"
              />
            </div>
            <div v-else>
              <v-select
                :v-model="(equipmentGroup = null)"
                :options="equipmentGroups"
                label="m_gid_m_gname"
                placeholder="เลือกกลุ่มวัสดุ"
                disabled
              />
            </div> </b-form-group
        ></b-col> -->
      <div class="row justify-content-between">
        <div class="row col-6 mb-1">
          <div class="ml-1">
            <b-button variant="success" @click="get_table_report"> ค้นหา </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="danger" @click="cc_report"> ยกเลิก </b-button>
          </div>
          <div class="ml-1">
            <b-button v-b-modal.modal-1 variant="outline-success"> ลงชื่อ </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="outline-primary" @click="exportExcel"> Export Exxel </b-button>
          </div>
        </div>
        <div class="mr-2">
          <b-button variant="outline-primary" @click="exportpdf"> พิมพ์รายงานแยกตามวัสดุกลุ่มงาน PDF </b-button>
        </div>
      </div>
      <b-modal id="modal-1" title="ลงชื่อ สรุปรายการรับเข้า-เบิกจ่าย-คงเหลือวัสด" ok-only ok-title="ตกลง"
        no-close-on-backdrop>
        <b-card-text>
          <b-form-group label="ประธานกรรมการ" label-for="basicInput">
            <v-select @input="a_position(staff_name1)" :options="alluser" label="firstname" placeholder="ประธานกรรมการ"
              v-model="staff_name1" />
          </b-form-group>
          <b-form-group label="ตำแหน่ง" label-for="basicInput">
            <v-select :options="alluser" label="firstname" placeholder="ตำแหน่ง" v-model="department1" />
          </b-form-group>
          <b-form-group label="กรรมการ" label-for="basicInput">
            <v-select @input="b_position(staff_name2)" :options="alluser" label="firstname" placeholder="กรรมการ"
              v-model="staff_name2" />
          </b-form-group>
          <b-form-group label="ตำแหน่ง" label-for="basicInput">
            <v-select :options="alluser" label="firstname" placeholder="ตำแหน่ง" v-model="department2" />
          </b-form-group>
          <b-form-group label="กรรมการเเละเลขานุการ" label-for="basicInput">
            <v-select @input="c_position(staff_name3)" :options="alluser" label="firstname"
              placeholder="กรรมการเเละเลขานุการ" v-model="staff_name3" />
          </b-form-group>
          <b-form-group label="ตำแหน่ง" label-for="basicInput">
            <v-select :options="alluser" label="firstname" placeholder="ตำแหน่ง" v-model="department3" />
          </b-form-group>
        </b-card-text>
      </b-modal>
      <!-- <div class="row col-4">
          <div class="col-6">
            <label for="">สถานะ</label>
            <b-form-select v-model="status" :options="statusA" />
          </div>
          <div class="row">
            <div class="ml-1 mt-2">
              <b-button variant="success"> ค้นหา </b-button>
            </div>
            <div class="ml-1 mt-2">
              <b-button variant="danger"> ยกเลิก </b-button>
            </div>
          </div>
        </div> -->
    </div>

    <!-- table -->
    <b-overlay :show="show" rounded="sm">
      <b-table :items="items" :fields="fields" responsive>
        <template #thead-top>
          <b-tr>
            <b-th colspan="3"><span class="sr-only"></span></b-th>
            <b-th variant="secondary" class="text-center" colspan="3">ยอดยกมา</b-th>
            <b-th variant="primary" class="text-center" colspan="3">รับเข้า</b-th>
            <b-th variant="danger" class="text-center" colspan="3">เบิก</b-th>
            <b-th variant="danger" class="text-center" colspan="3">คงเหลือ</b-th>
          </b-tr>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import { DatePicker } from "v-calendar";
import _ from "lodash";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    DatePicker,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  data() {
    return {
      headerInputDate: "",
      date1: new Date(),
      date2: new Date(),
      date: {
        startDate: new Date(),
        endDate: new Date(),
      },
      staff_name1: "",
      staff_name2: "",
      staff_name3: "",
      department1: "",
      department2: "",
      department3: "",
      alluser: [],
      selected: null,
      options: [{ value: null, text: "เลือกปีงบประมาณ" }],
      equipment_code: "",
      e_number: "",
      serial_number: "",
      e_name: "",
      currentPage: 1,
      pageLength: 100,
      total: "",
      show: false,
      dir: false,
      workgroup: null,
      workgroups: [],
      equipmentGroup: null,
      equipmentGroups: [],
      department: "",
      departments: [],
      fields: [
        { key: "m_id", label: "หมายเลขวัสดุ" },
        { key: "m_name", label: "ชื่อวัสดุ" },
        {
          key: "unittype",
          label: "หน่วยนับ",
        },
        { key: "remainAmount", label: "จำนวน" },
        { key: "unitprice_vat", label: "ราคา" },
        { key: "sum1", label: "ราคารวม" },
        { key: "amountReceive2", label: "จำนวน" },
        { key: "unitprice_vat2", label: "ราคา" },
        { key: "sum2", label: "ราคารวม" },
        { key: "drawAmount", label: "จำนวน" },
        { key: "unitprice_vat3", label: "ราคา" },
        { key: "sum3", label: "ราคารวม" },
        { key: "remainingAmount4", label: "จำนวน" },
        { key: "unitprice_vat4", label: "ราคา" },
        { key: "sum4", label: "ราคารวม" },
      ],
      items: [],
      rows: [],
      searchTerm: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      new_arr: [],
      init: 0,
      inspector: {},
      contentData: [],
      get_sum: '',
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  created() {
    //this.checkLogout();
    const y = new Date().getFullYear();
    const yy = y + 543;
    this.options.push({ value: yy, text: yy });
    for (let index = 1; index < 5; index++) {
      this.options.push({ value: yy - index, text: yy - index });
    }
    this.workgroups_table();
    this.equipment_table();
    this.get_data_table_user();
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },

    async a_position(value) {
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.department1 = res.data.message.data[0].dep_id;
    },
    async b_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.department2 = res.data.message.data[0].dep_id;
    },
    async c_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.department3 = res.data.message.data[0].dep_id;
    },
    async get_data_table_user() {
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      const res_user = res.data.message.data.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
    },
    exportpdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [10, 80, 10, 60],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "รายงานผลการตรวจสอบวัสดุเเยกตามกลุ่มงาน",
        },
        header: [
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
            margin: [0, 15, 0, 0],
          },
          {
            text: "สรุปรายการรับเข้า-เบิกจ่าย-คงเหลือวัสดุ",
            style: "header",
          },

          {
            text: `ตั้งเเต่วันที่ ${parseInt(this.date.startDate.split("-")[2])} ${this.month[parseInt(this.date.startDate.split("-")[1])]
              } ${Number(this.date.startDate.split("-")[0]) + 543} - ${parseInt(this.date.endDate.split("-")[2])} ${this.month[parseInt(this.date.endDate.split("-")[1])]
              } ${Number(this.date.endDate.split("-")[0]) + 543}`,
            style: "header",
          },
          {
            text: `${this.workgroup.work_gname}`,
            style: "header",
            alignment: "center",
          },
        ],

        footer: {
          columns: [
            {
              text: `(ลงชื่อ)...................................................................(ประธานกรรมการ)
              (${this.staff_name1.firstname == undefined
                  ? "..........................................................................."
                  : this.staff_name1.firstname
                })
              ตำเเหน่ง: ${this.staff_name1.firstname == undefined
                  ? "..........................................................................."
                  : this.department1
                }
              `,
              alignment: "center",
              style: "text",
            },
            {
              text: `(ลงชื่อ)...................................................................(กรรมการ)
              (${this.staff_name2.firstname == undefined
                  ? "..........................................................................."
                  : this.staff_name2.firstname
                })
              ตำเเหน่ง: ${this.staff_name2.firstname == undefined
                  ? "..........................................................................."
                  : this.department2
                }
              `,
              alignment: "center",
              style: "text",
            },
            {
              text: `(ลงชื่อ)...................................................................(กรรมการเเละเลขานุการ)
              (${this.staff_name3.firstname == undefined
                  ? "..........................................................................."
                  : this.staff_name3.firstname
                })
              ตำเเหน่ง: ${this.staff_name3.firstname == undefined
                  ? "..........................................................................."
                  : this.department3
                }
              `,
              alignment: "center",
              style: "text",
            },
          ],
        },
        content: this.contentData,
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 0, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 6,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 8,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 15, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            fontSize: 6,
          },
          text_right: {
            alignment: "right",
            fontSize: 6,
          },
          text_left: {
            alignment: "left",

            fontSize: 6,
          },
          text: {
            fontSize: 9,
          },
        },
      };
      pdfMake
        .createPdf(docDefinition)
        .download(`รายงานผลการตรวจสอบวัสดุแยกตามกลุ่มงาน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
      //pdfMake.createPdf(docDefinition).open();
    },
    exportExcel() {
      saveExcel({
        data: this.items,
        fileName: `รายงานผลการตรวจสอบวัสดุแยกตามกลุ่มงาน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`,
        columns: [
          {
            title: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: `สรุปรายการรับเข้า-เบิกจ่าย-คงเหลือวัสดุ`,
                headerCellOptions: { textAlign: "center" },
                children: [
                  {
                    title: `ตั้งเเต่วันที่ ${parseInt(this.date.startDate.split("-")[2])} ${this.month[parseInt(this.date.startDate.split("-")[1])]
                      } ${Number(this.date.startDate.split("-")[0]) + 543} - ${parseInt(
                        this.date.endDate.split("-")[2]
                      )} ${this.month[parseInt(this.date.endDate.split("-")[1])]} ${Number(this.date.endDate.split("-")[0]) + 543
                      }`,
                    headerCellOptions: { textAlign: "center" },
                    children: [
                      {
                        title: `กลุ่มงาน ${this.workgroup.work_gname}`,
                        headerCellOptions: { textAlign: "center" },
                        children: [
                          {
                            title: "หมายเลขวัสดุ",
                            field: "m_id",
                          },
                          {
                            title: "ชื่อวัสดุ",
                            field: "m_name",
                          },
                          {
                            title: "หน่วยนับ",
                            field: "unittype",
                          },

                          {
                            title: "จำนวนที่มีอยู่ต้นเดือน",
                            headerCellOptions: { textAlign: "center" },
                            children: [
                              { field: "remainAmount", title: "จำนวน" },
                              { field: "unitprice_vat", title: "ราคา" },
                              { field: "sum1", title: "ราคารวม" },
                            ],
                          },
                          {
                            title: "รับเข้า",
                            headerCellOptions: { textAlign: "center" },
                            children: [
                              { field: "amountReceive2", title: "จำนวน" },
                              { field: "unitprice_vat2", title: "ราคา" },
                              { field: "sum2", title: "ราคารวม" },
                            ],
                          },
                          {
                            title: "เบิก",
                            headerCellOptions: { textAlign: "center" },
                            children: [
                              { field: "drawAmount", title: "จำนวน" },
                              { field: "unitprice_vat3", title: "ราคา" },
                              { field: "sum3", title: "ราคารวม" },
                            ],
                          },
                          {
                            title: "คงเหลือ",
                            headerCellOptions: { textAlign: "center" },
                            children: [
                              { field: "remainingAmount4", title: "จำนวน" },
                              { field: "unitprice_vat4", title: "ราคา" },
                              { field: "sum4", title: "ราคารวม" },
                            ],
                          },
                        ],
                      },
                      {},
                    ],
                  },
                  {
                    title: ``,
                    headerCellOptions: { textAlign: "center" },
                    children: [],
                  },
                ],
              },
              {
                title: ``,
                headerCellOptions: { textAlign: "center" },
                children: [],
              },
            ],
          },
        ],
      });
    },
    cc_report() {
      this.items = [];
      this.date.startDate = "";
      this.date.endDate = "";
      this.workgroup = null;
    },
    aletall(text_alet) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: text_alet,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    sum(val) {
      var sum1 = val
      if (sum1.indexOf('.') < 0) {
        console.log(sum1);
        this.get_sum = sum1
      } else {
        const number = sum1;
        const numberAsString = number.toString(); // แปลงเป็นสตริง
        const parts = numberAsString.split('.'); // แยกเป็นส่วนจำนวนเต็มและทศนิยม
        const result = `${parts[0]}.${parts[1].substring(0, 2)}`; // ตัดทศนิยมให้เหลือ 2 ตำแหน่ง

        console.log(result); // แสดงผลลัพธ์เป็น "1456.12"
        this.get_sum = result
      }
      
    },
    async get_table_report() {
      if (this.workgroup === null) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `กรุณาเลือกกลุ่มงาน`,
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      this.show = true;

      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      if (this.date.startDate != "" && this.date.endDate != "") {
        // const url = `${API}summaryMaterial?startDate=2022-04-01&endDate=2022-08-09&work_gid=06&_page=1&_limit=100`;
        const url = `${API}summaryMaterial?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=1&_limit=100`;
        console.log(url)
        const response = await axios.get(url, header);

        const { total } = response.data.message;
        let idx = Math.ceil(Number(total) / 100);
        let ss = [];
        let sum1 = 0;
        let sum2 = 0;
        let sum3 = 0;
        let sum4 = 0;

        for (let index = 1; index <= idx; index++) {
          console.log(index);
          const url_pdf = `${API}summaryMaterial?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=${index}&_limit=100`;
          const res_pdfs = await axios.get(url_pdf, header);
          console.log(res_pdfs);
          sum1 +=  res_pdfs.data.message.sum.sum1;
          sum2 += res_pdfs.data.message.sum.sum2;
          sum3 += res_pdfs.data.message.sum.sum3;
          sum4 += res_pdfs.data.message.sum.sum4;
          ss.push(...res_pdfs.data.message.result);
          console.log(ss);
        }

        const res1 = ss;
        console.log(ss);
        res1.length === 0 && this.aletall("ไม่มีข้อมูล");
        console.log(res1);
        const lastData = [];
        let lastData2 = [];
        let m_gnames = "";
        res1.map((res, index) => {
          console.log(res);
          const raw = {
            m_name: "",
            amountReceive2: "",
            drawAmount: "",
            m_gid: "",
            m_gname: "",
            m_id: "",
            remainAmount: "",
            remainingAmount4: "",
            sum1: "",
            sum2: "",
            sum3: "",
            sum4: "",
            unitprice_vat: "",
            unitprice_vat2: "",
            unitprice_vat3: "",
            unitprice_vat4: "",
          };

          // if(m_gnames ==  res.m_gname){
          //   lastData2.push({ [res.m_gname]: [] });
          // }else{
          //   lastData2.push({ [res.m_gname]: [] });
          // }
          if (res.m_gname === "" || res.m_gname !== m_gnames) {
            // header
            raw["m_name"] = res.m_gname;

            lastData.push(raw);
            m_gnames = res.m_gname;
            console.log(res.m_gname);
            // for (let index = 0; index < res1.length; index++) {
            // if (res.m_gname == res.m_gname) {
            lastData2.push({ [res.m_gname]: [] });

            console.log({ [res.m_gname]: [] });
            // }

            console.log(lastData2);
          }
          // }

          lastData.push(res);
          console.log(lastData);
          console.log(lastData2);
          for (let i in lastData2) {
            console.log(lastData2[i]);
            console.log(res);
            if (res.m_gname == Object.keys(lastData2[i])) {
              lastData2[i][Object.keys(lastData2[i])].push(res);
            }
            //lastData2[i][Object.keys(lastData2[i])].push(res);
          }

          console.log(lastData2);
        });
        const res_data = lastData.map((el) => {
          console.log(el);
          return {
            ...el,
            sum1: `${el.m_id
              ? Number(el.sum1)
                .toFixed(2)
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
              }`,
            sum2: `${el.m_id
              ? Number(el.sum2)
                .toFixed(2)
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
              }`,
            sum3: `${el.m_id
              ? Number(el.sum3)
                .toFixed(2)
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
              }`,
            sum4: `${el.m_id
              ? Number(el.sum4)
                .toFixed(2)
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
              }`,
            unitprice_vat: `${el.m_id
              ? Number(el.unitprice_vat)
                .toFixed(2)
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
              }`,
            unitprice_vat2: `${el.m_id
              ? Number(el.unitprice_vat2)
                .toFixed(2)
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
              }`,
            unitprice_vat3: `${el.m_id
              ? Number(el.unitprice_vat3)
                .toFixed(2)
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
              }`,
            unitprice_vat4: `${el.m_id
              ? Number(el.unitprice_vat4)
                .toFixed(2)
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
              : ""
              }`,
          };
        });
        // console.log(lastData2);
        //เพิ่มแถวว่าง 1 แถวไว้ท้ายสุด สำหรับใส่ sum
        for (let i in lastData2) {
          lastData2[i][Object.keys(lastData2[i])].push({
            m_name: "",
            amountReceive2: "",
            drawAmount: "",
            m_gid: "",
            m_gname: "",
            m_id: "",
            remainAmount: "",
            remainingAmount4: "",
            sum1: "",
            sum2: "",
            sum3: "",
            sum4: "",
            unitprice_vat: "",
            unitprice_vat2: "",
            unitprice_vat3: "",
            unitprice_vat4: "",
          });
        }

        const res = lastData2.map((res, index) => {
          let sumAll = [];
          return {
            [Object.keys(res)[0]]: res[Object.keys(res)].map((res2, index, arr) => {
              let result = [
                { text: res2.m_id, style: "text_center" },
                { text: res2.m_name, style: "text_left" },
                { text: res2.unittype, style: "text_center" },
                { text: res2.remainAmount, style: "text_right" },
                { text: res2.unitprice_vat, style: "text_right" },
                {
                  text:
                    index >= arr.length - 1
                      ? sumAll[0]["sum1"].text
                      : Number(res2.sum1)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                  style: "text_right",
                },
                { text: res2.amountReceive2, style: "text_right" },
                { text: res2.unitprice_vat2, style: "text_right" },
                {
                  text:
                    index >= arr.length - 1
                      ? sumAll[1]["sum2"].text
                      : Number(res2.sum2)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                  style: "text_right",
                },
                { text: res2.drawAmount, style: "text_right" },
                { text: res2.unitprice_vat3, style: "text_right" },
                {
                  text:
                    index >= arr.length - 1
                      ? sumAll[2]["sum3"].text
                      : Number(res2.sum3)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                  style: "text_right",
                },
                { text: res2.remainingAmount4, style: "text_right" },
                { text: res2.unitprice_vat4, style: "text_right" },
                {
                  text:
                    index >= arr.length - 1
                      ? sumAll[3]["sum4"].text
                      : Number(res2.sum4)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                  style: "text_right",
                },
                { text: " ", style: "text_center" },
                { text: " ", style: "text_center" },
                { text: " ", style: "text_center" },
              ];

              index === 0
                ? ((sumAll = []),
                  sumAll.push({ sum1: { text: Number(res2.sum1), style: "text_right" } }),
                  sumAll.push({ sum2: { text: Number(res2.sum2), style: "text_right" } }),
                  sumAll.push({ sum3: { text: Number(res2.sum3), style: "text_right" } }),
                  sumAll.push({ sum4: { text: Number(res2.sum4), style: "text_right" } }))
                : ((sumAll[0]["sum1"].text = sumAll[0]["sum1"].text + Number(res2.sum1)),
                  (sumAll[1]["sum2"].text = sumAll[1]["sum2"].text + Number(res2.sum2)),
                  (sumAll[2]["sum3"].text = sumAll[2]["sum3"].text + Number(res2.sum3)),
                  (sumAll[3]["sum4"].text = sumAll[3]["sum4"].text + Number(res2.sum4)));

              //แปลงตัวเลขให้เป็น string ใส่ , และทำให้เหลือ 2 ตำแหน่ง
              //แปลงตัวเลขให้เป็น string ใส่ , และทำให้เหลือ 2 ตำแหน่ง
              return result.map((res, index) => {
                const noFn = [3, 6, 9, 12];
                return typeof res.text === "number" && !noFn.includes(index)
                  ? res.text
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  : res;
              });
            }),
          };
        });

        // console.log(res);

        // console.log("----------------------------------");

        // console.log(res_data);
        // console.log(lastData2);
        // console.log(lastData);
        this.items = res_data.map((xcel) => {
          // console.log(xcel);
          return {
            ...xcel,
            unitprice_vat: `${xcel.m_gname && xcel.m_id ? xcel.unitprice_vat : ""}`,
            unitprice_vat2: `${xcel.m_gname && xcel.m_id ? xcel.unitprice_vat2 : ""}`,
            unitprice_vat3: `${xcel.m_gname && xcel.m_id ? xcel.unitprice_vat3 : ""}`,
            unitprice_vat4: `${xcel.m_gname && xcel.m_id ? xcel.unitprice_vat4 : ""}`,
            sum1: `${xcel.m_gname && xcel.m_id ? xcel.sum1 : ""}`,
            sum2: `${xcel.m_gname && xcel.m_id ? xcel.sum2 : ""}`,
            sum3: `${xcel.m_gname && xcel.m_id ? xcel.sum3 : ""}`,
            sum4: `${xcel.m_gname && xcel.m_id ? xcel.sum4 : ""}`,
          };
        });

        this.show = false;
        console.log(res);
        const contentData = res.map((res, idx, arr) => {
          console.log(res);
          console.log(`${Object.keys(arr[idx])[0]}`);
          console.log(idx);
          console.log(arr);

          return [
            // { text: `${Object.keys(arr[idx])[0]}`, pageOrientation: "landscape" },
            idx == 0 ? { text: `${Object.keys(arr[idx])[0]}`, pageOrientation: "landscape" } : {},
            // : arr.length - 1 === 0
            // ? { text: `${Object.keys(arr[idx])[0]}`, pageOrientation: "landscape" }
            // : {},

            {
              style: "tableHeader",
              table: {
                headerRows: 3,
                keepWithHeaderRows: 2,
                widths: [
                  "auto",
                  "*",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                ],
                body: [
                  [
                    {
                      text: "หมายเลขวัสดุ",
                      style: "tableHeader",
                      rowSpan: 3,
                    },
                    {
                      text: "ชื่อวัสดุ",
                      style: "tableHeader",
                      rowSpan: 3,
                    },
                    {
                      text: "หน่วยนับ",
                      style: "tableHeader",
                      rowSpan: 3,
                    },
                    {
                      text: `จำนวนที่มีอยู่ต้นเดือน`,
                      style: "tableHeader",

                      colSpan: 3,
                    },
                    {},
                    {},
                    {
                      text: "รับเข้า",
                      style: "tableHeader",
                      colSpan: 3,
                    },
                    {},
                    {},
                    {
                      text: "เบิกของ",
                      style: "tableHeader",
                      colSpan: 3,
                    },
                    {},
                    {},
                    {
                      text: "คงเหลือ",
                      style: "tableHeader",
                      colSpan: 3,
                    },
                    {},
                    {},
                    {
                      text: "คณะกรรมการตรวจนับวัสดุให้ความเห็น",
                      style: "tableHeader",
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                      // colSpan: 2,
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                      // colSpan: 2,
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },

                    {
                      text: `จำนวน`,
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: `ราคา`,
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: `ราคารวม`,
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "จำนวน",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ราคา",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ราคารวม",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "จำนวน",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ราคา",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ราคารวม",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "จำนวน",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ราคา",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ราคารวม",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ถูกต้อง",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ขาด",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "เกิน",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                  ],
                  [
                    {
                      text: "",

                      alignment: "center",
                    },
                    {
                      text: "",

                      alignment: "center",
                    },
                    {
                      text: "",

                      alignment: "center",
                    },

                    {
                      text: ``,

                      alignment: "center",
                    },
                    {
                      text: ``,

                      alignment: "center",
                    },
                    {
                      text: ``,
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                  ],
                  ...res[Object.keys(res)[0]],
                ],
              },
            },
            idx !== arr.length - 1
              ? {
                text: `${Object.keys(arr[idx + 1])[0]}`,
                pageOrientation: "landscape",
                pageBreak: "before",
              }
              : {},
          ];
        });
        this.contentData = contentData;
      } else {
        //alet!!!
        this.aletall("กรุณาเลือกวันที่");
      }

      console.log(this.contentData);

      return;

      if (this.date.startDate == "" && this.date.endDate == "") {
        // const url = `${API}summaryMaterial?startDate=${this.date.startDate}&endDate=${this.date.endDate}&work_gid=${this.workgroup.work_gid}&_page=1&_limit=100`;

        const xx = this.items.map((res) => {
          // if (res.m_gname === '' || res.m_gname !== m_gnames) {
          //   lastData.push({
          //     m_name: res.m_gname,
          //     amountReceive2: '',
          //     drawAmount: '',
          //     m_gid: '',
          //     m_gname: '',
          //     m_id: '',
          //     remainAmount: '',
          //     remainingAmount4: '',
          //     sum1: '',
          //     sum2: '',
          //     sum3: '',
          //     sum4: '',
          //     unitprice_vat: '',
          //     unitprice_vat2: '',
          //     unitprice_vat3: '',
          //     unitprice_vat4: '',
          //   });
          //   m_gnames = res.m_gname;
          // }

          res.unitprice_vat = Number(res.unitprice_vat)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

          res.unitprice_vat2 = Number(res.unitprice_vat2)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

          res.unitprice_vat3 = Number(res.unitprice_vat3)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

          res.unitprice_vat4 = Number(res.unitprice_vat4)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

          lastData.push(res);
        });

        lastData.push(res.data.message.sum);
        lastData.map((res, index) => {
          if (res.m_id !== "") {
            lastData[index].sum1 = Number(res.sum1)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            lastData[index].sum2 = Number(res.sum2)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            lastData[index].sum3 = Number(res.sum3)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            lastData[index].sum4 = Number(res.sum4)
              .toFixed(2)
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          }
        });
        this.items = lastData;
        console.log(this.items);
      }

      this.show = false;
    },
    async equipment_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}materialGroups?_page=1&_limit=100`;
      const res = await axios.get(url, header);

      this.equipmentGroups = res.data.message.data.map((element) => {
        return {
          ...element,
          m_gid_m_gname: `${element.m_gid} - ${element.m_gname}`,
        };
      });
    },
    async workgroups_table() {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}workgroups?_page=1&_limit=100&_sort=-1`;
      const res = await axios.get(url, header);
      this.workgroups = res.data.message.data.map((element) => {
        return {
          ...element,
          work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
    },
    async departments_table(value) {
      const { access_token } = await this.access_token();
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}departments?work_gid=${value.work_gid}`;
      const res = await axios.get(url, header);

      this.departments = res.data.message.data;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      console.log(val);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
            this.date.startDate = dete_val;
            break;
          case "date2":
            this.date.endDate = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
